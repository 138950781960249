.customDatePicker,
.customDatePicker > div.react-datepicker-wrapper,
.customDatePicker > div > div.react-datepicker__input-container,
.customDatePicker > div > div.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker__view-calendar-icon input {
  height: 38px;
  padding: 6px 10px 5px 32px;
}

.react-datepicker__calendar-icon {
  margin-top: 3px;
}
